/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

import { makeQueryablePromise, logger } from '@/inc/utils'
import { Chrome, QueryablePromise, Resource } from '@/inc/types'

const { VUE_APP_RELEASE } = process.env
const isProd = VUE_APP_RELEASE === 'production' || VUE_APP_RELEASE === 'pre'

type ApiResponse = Chrome | Resource
interface CacheData {
  response: QueryablePromise<AxiosResponse<ApiResponse>>
  created: number
}

/**
 * Cache HTTP responses
 */
const expires = isProd ? 1000 * 60 : -1

export const data: Map<string, CacheData> = new Map()
export const fetch = (
  path: string,
  url: string,
  config?: AxiosRequestConfig
) => {
  if (typeof window === 'undefined') {
    // No cache on server side
    logger.log('[(no)CACHE:server]')

    return axios.get(url, config)
  }

  logger.log('[CACHE:client]', data.has(path), isProd, expires)

  if (!data.has(path) || data.get(path)!.created < Date.now() - expires) {
    logger.log('[CACHE:client:set]')

    data.set(path, {
      response: makeQueryablePromise(axios.get(url, config)),
      created: Date.now(),
    })
  }

  return data.get(path)!.response
}









































































































import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'

export default defineComponent({
  name: 'GHeader',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const scrollBottom = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }

    return {
      ...mapGetters(ctx, ['chrome']),
      scrollBottom,
    }
  },
})

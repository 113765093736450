




























import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

export default defineComponent({
  name: 'gallery',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    let gallery
    const list = ref<null | HTMLElement>(null)

    onMounted(async () => {
      const Flickity = await import(
        /* webpackChunkName: 'vendor-flickity' */ 'flickity'
      ).then(module => module.default)

      gallery = new Flickity(list.value as HTMLElement, {
        prevNextButtons: false,
        pageDots: false,
      })
    })

    onUnmounted(() => {
      gallery.destroy()
    })

    return {
      list,
    }
  },
})

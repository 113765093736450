



































import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GWysiwyg',
  props: {
    wysiwyg: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const template = /^<.+>$/.test(props.wysiwyg)
      ? props.wysiwyg
      : `<div class="wysiwyg-inner">${props.wysiwyg}</div>`
    const runtimeComponent = computed(() => ({
      template,
    }))

    return {
      runtimeComponent,
    }
  },
})

import { GenericObject, Language } from '@/inc/types'

/**
 * Languages
 */
export const langDefault: Language = 'fr'
export const langAvailable = ['fr'] as const

/**
 * URLs, segments, endpoints
 */
export const segments = {} as GenericObject
export const endpointDefault = 'pages'

// API URLs
export const getApiUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`
export const getThumborUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_THUMBOR_PATH}`

/**
 * GTM IDs
 */
export const gtm = {
  production: 'GTM-MFG5MRK',
  staging: 'GTM-MFG5MRK',
}

/**
 * Options
 */
export const useCookiebar = false


























































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'text_only',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
})

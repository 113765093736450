



















import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import NotFound from '@/views/NotFound.vue'

export default Vue.extend({
  name: 'App',
  components: {
    // Home,
    // DefaultTemplate,
    NotFound,
  },
  computed: {
    ...mapGetters(['meta', 'chrome', 'template']),
  },
  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
    }),
    ...mapActions(['fetchChrome']),
  },
  // Needed for SSR rendering
  // https://vapperjs.org/data-prefetching.html#data-prefetch
  // BUT does not work with composition-api + defineComponent
  needSerialize: true,
  async created() {
    await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })

    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      this.setResource(this.$root.$options.$resource)
    } else if (!this.$isServer && document.body.dataset.rendering === 'SPA') {
      this.setResource(this.$router.$resource)
    }
  },
  watch: {
    $route() {
      this.setResource(this.$router.$resource)
    },
  },

  head() {
    return this.meta
  },
})

import Vue, { VueConstructor } from 'vue'
import { RouteConfig } from 'vue-router'

import { langDefault, langAvailable } from '@/inc/app.config'
import VRoot from '@/views/Root.vue'
// Import { Language } from '@/inc/types'

// DEV: error on "rebuild", see NOTES.md
// const loadView = (view: string) => () =>
//   import(
//     /* webpackChunkName: 'v-[request]' */ `@/views/${view.toLowerCase()}/${view}.vue`
//   )

// Format segments to (a|b)
// const formatSegment = (key: string): string =>
//   Array.isArray(segments[key]) ? `(${segments[key].join('|')})` : segments[key]

let routes: RouteConfig[] = [
  {
    path: '/(|accueil|home)',
    name: 'home',
    // DEV not working.
    // component: loadView('Home'),
    component: () =>
      import(/* webpackChunkName: 'v-home' */ '@/views/Home.vue'),
    meta: {
      ssr: true,
      scrollTop: true,
    },
  },
  {
    path: '/:endpoint(article)/:slug',
    name: 'article',
    component: () =>
      import(/* webpackChunkName: 'v-article' */ '@/views/Article.vue'),
    meta: {
      endpoint: 'article',
      ssr: true,
      scrollTop: true,
    },
  },
  {
    path: '/:slug/:sub?',
    name: 'page',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
      scrollTop: true,
    },
  },
  {
    path: '/*',
    name: 'all',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
      scrollTop: true,
    },
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: VRoot as VueConstructor<Vue>,
      children: routes.map(r => {
        // Remove leading slash
        r.path = r.path.replace(/^\//, '')

        return r
      }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
  ]
}

export { routes }

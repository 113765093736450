










































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GCard',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    return {}
  },
})

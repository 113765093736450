


































































import { defineComponent, ref } from '@vue/composition-api'
import { parseRoute } from '@/inc/utils'

export default defineComponent({
  name: 'VNotFound',
  setup(props, ctx) {
    const { api, endpoint, resource } = parseRoute(ctx.root.$route)
    const emoji = ref('¯\\_(ツ)_/¯')
    const btn = {
      /* eslint-disable */
      label: 'Page d\'accueil',
      /* eslint-enable */
      url: '/',
    }

    return {
      url: `${api}/${endpoint}/${resource}`,
      emoji,
      btn,
    }
  },
})

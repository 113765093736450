





























































import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'

export default defineComponent({
  name: 'GLegals',

  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(_props, ctx) {
    const { root } = ctx
    const enter = () => {
      console.log('enter')
      root.$emit('enter')
    }
    const leave = () => {
      console.log('leave')
      root.$emit('leave')
    }

    return {
      ...mapGetters(ctx, ['chrome']),
      enter,
      leave,
    }
  },
})







































































import { ref, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GDuo',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const duo = ref<HTMLElement | null>(null)
    const scrollTop = () => {
      if (!duo.value) {
        return
      }
      window.scrollTo({
        top: window.scrollY + duo.value.offsetHeight,
        left: 0,
        behavior: 'smooth',
      })
    }

    return {
      duo,
      scrollTop,
    }
  },
})

import { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import VueRouter, { Route } from 'vue-router'

import { getApiUrl, endpointDefault } from '@/inc/app.config'
import { cache, logger } from '@/inc/utils'
import { Resource } from '@/inc/types'

/**
 * Parse route to build an API request like ${api}/${endpoint}/${resource}.
 */
export const parseRoute = (to: Route) => {
  const { path, meta, params, query } = to
  const api = meta?.api || getApiUrl()
  let endpoint = endpointDefault
  let resource = path

  if (params.lang) {
    // Remove lang from path
    resource = resource.replace(new RegExp(`^/${params.lang}`), '')
  }

  if (meta?.endpoint) {
    // Set API endpoint
    ;({ endpoint } = meta)
    // Remove endpoint from path
    resource = resource.replace(
      new RegExp(`^/${params.endpoint || params.pathMatch}`),
      ''
    )
  }

  if (query.preview === 'true') {
    // Manage preview
    const { page_id: pageId, id: postId, p: postType } = query

    if (pageId) {
      resource = `/${pageId}`
    }

    if (postType && postId) {
      endpoint = postType as string
      resource = `/${postId}`
    }
  }

  if (query.page) {
    resource += `?page=${query.page}`
  }

  resource = resource.replace(/^\//, '')

  return {
    api,
    endpoint,
    resource,
  }
}

/**
 * Save resource to context
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const save = (ctx: any | VueRouter, data: Resource, ssr: boolean) => {
  if (ssr) {
    // Save to server response
    ctx.$resource = data
  } else {
    // Can not commit the store here
    // It's not compatible with VueJS lifecycle: content changed but views/components did not yet…
    // So there are matching error between new content from store and existing (old) components
    // We should use route watcher (App)
    // ctx.app.$store.commit('SET_RESOURCE', data)

    // Save to router object
    ctx.$resource = data
  }
}

/**
 * Main fetch based on routing.
 * Executed SSR (or client side as a fallback)
 * and then on navigation.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetch(to: Route, ctx: any | VueRouter, ssr = false) {
  const config: AxiosRequestConfig = {}
  const { fullPath, meta, query } = to

  // No fetch for static routes
  // eslint-disable-next-line
  // TODO: what about metadata (title, head, …)
  if (meta && meta.static) {
    ctx.$resource = {
      content: {},
      languages: {},
    }

    return
  }

  const { api, endpoint, resource } = parseRoute(to)

  if (query && query.preview === 'true') {
    // Preview header
    config.headers = {
      'x-preview': true,
    }
  }

  const url = `${api}/${endpoint}/${resource}`

  logger.info('[fetch]', url)

  try {
    if (ssr) {
      // No cache server-side because instance is "shared" across all clients, forever :)
      cache.data.clear()
    }
    const response = (await cache.fetch(
      fullPath,
      url,
      config
    )) as AxiosResponse<Resource>

    // Nested, so what?
    // if (params.nested || meta.nested) {}

    save(ctx, response.data, ssr)
  } catch (error) {
    const { response } = error as AxiosError<Resource>

    if (response && response.status === 404) {
      save(ctx, response.data, ssr)

      if (ssr) {
        // Add 404 status to the server response
        ctx.statusCode = response.status
        ctx.statusMessage = response.statusText
      }
    } else {
      logger.error('[fetch]', error)
    }
  }
}

/**
 * Fetch resource from API directly
 * without being related on routing.
 */
export function fetchLive(to: Route) {
  const { fullPath } = to
  const { api, endpoint, resource } = parseRoute(to)
  const url = `${api}/${endpoint}/${resource}`

  logger.info('[fetchLive]', url)

  return cache.fetch(fullPath, url)
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray, isObject } from '@/inc/utils'
import { GenericObject } from '@/inc/types'

export const toKebab = (str: string): string =>
  str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
export const toCamel = (s: string) =>
  s.replace(/([-_][a-z])/gi, $1 =>
    $1.toUpperCase().replace('-', '').replace('_', '')
  )

export const pascalToKebab = (str: string): string => toKebab(str)
export const camelToKebab = (str: string): string => toKebab(str)

export const keysToCamel = (o: any) => {
  if (isObject(o)) {
    const n = {} as GenericObject

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: any) => keysToCamel(i))
  }

  return o
}



























































































































import { ref, onMounted, defineComponent } from '@vue/composition-api'
import { gsap } from 'gsap'
import { SplitText } from '@/inc/vendor/gsap-extra/SplitText'
gsap.registerPlugin(SplitText)

export default defineComponent({
  name: 'GHero',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(_props, ctx) {
    const hero = ref<HTMLElement | null>(null)
    const onboard = ref<HTMLElement | null>(null)
    const text = ref<HTMLElement | null>(null)
    const picture = ref<HTMLElement | null>(null)
    const pictureContainer = ref<HTMLElement | null>(null)

    // Need review
    const basicOpacity = 0.3
    const basicScale = 1
    const handlingScroll = () => {
      const normalizeOpacity = window.scrollY / 800
      const normalizeScale = window.scrollY / 8000
      const opacity = basicOpacity + normalizeOpacity
      const scale = basicScale + normalizeScale
      if (window.scrollY >= window.innerHeight) {
        if (hero.value) {
          hero.value.classList.add('stop-scroll')
        }
        window.removeEventListener('scroll', handlingScroll)
      }
      gsap.to(picture.value, {
        duration: 1,
        opacity,
        scale,
        ease: 'power4.out',
      })
    }

    const shuffle = array => {
      let currentIndex = array.length
      let temporaryValue
      let randomIndex

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    }
    const scrollTop = () => {
      window.scrollTo({
        top: hero.value?.offsetHeight,
        left: 0,
        behavior: 'smooth',
      })
    }
    onMounted(() => {
      const splitTitle = new SplitText(text.value, {
        type: 'words',
      })
      const splitTitleStrong = document.querySelectorAll('strong')

      const tl = gsap.timeline({
        onComplete: () => {
          ctx.root.$el.classList.remove('is-locked')
          if (onboard.value) {
            onboard.value.style.display = 'none'
          }
        },
      })

      tl.add('onBoarding')
        .to(
          onboard.value,
          {
            duration: 2,
            opacity: 0,
            ease: 'power4.in',
          },
          'onBoarding+=0.5'
        )
        .from(
          shuffle([...splitTitleStrong]),
          {
            duration: 0.5,
            opacity: 0,
            stagger: 0.1,
            ease: 'power4.out',
          },
          'onBoarding+=2.2'
        )
        .to(
          shuffle(splitTitle.words),
          {
            duration: 2,
            opacity: 1,
            stagger: 0.01,
            ease: 'power4.out',
          },
          '>'
        )
        .to(
          picture.value,
          {
            duration: 1,
            opacity: 0.3,
            ease: 'power4.out',
          },
          '<+1'
        )

      // Scroll Handling
      window.addEventListener('scroll', handlingScroll)
    })

    return {
      // App,
      hero,
      onboard,
      text,
      picture,
      pictureContainer,
      shuffle,
      scrollTop,
    }
  },
})






































































































import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'

export default defineComponent({
  name: 'GContact',
  setup(props, ctx) {
    const { root } = ctx
    const enter = () => {
      root.$emit('enter')
    }
    const leave = () => {
      root.$emit('leave')
    }

    const scrollTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    return {
      ...mapGetters(ctx, ['chrome']),
      enter,
      leave,
      scrollTop,
    }
  },
})






















































import { ref, onMounted, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GCursor',

  setup(_props, ctx) {
    const { root } = ctx
    const cursor = ref<HTMLElement | null>(null)
    const arrow = ref<HTMLElement | null>(null)
    const mousePosition = {
      x: 0,
      y: 0,
    }
    const updateCursor = () => {
      if (!cursor.value) {
        return
      }

      const cursorZeroX = cursor.value.clientWidth / 2
      const cursorZeroY = cursor.value.clientHeight / 2
      const cursorX = mousePosition.x - cursorZeroX
      const cursorY = mousePosition.y - cursorZeroY

      cursor.value.style.transform = `translate(${cursorX}px, ${cursorY}px)`

      window.requestAnimationFrame(updateCursor)
    }
    const mouseEnter = () => {
      if (cursor.value) {
        cursor.value.classList.add('is-link')
      }
    }
    const mouseLeave = () => {
      if (cursor.value) {
        cursor.value.classList.remove('is-link')
      }
    }

    root.$on('enter', mouseEnter)
    root.$on('leave', mouseLeave)

    onMounted(() => {
      window.addEventListener('mousemove', e => {
        setTimeout(() => {
          if (cursor.value) {
            cursor.value.style.opacity = '1'
          }
          mousePosition.x = e.clientX
          mousePosition.y = e.clientY
        }, 50)
      })

      window.addEventListener('scroll', () => {
        if (
          window.innerHeight + window.scrollY >=
            document.body.offsetHeight - 400 &&
          cursor.value
        ) {
          cursor.value.classList.add('is-bottom')
        } else if (
          cursor.value &&
          cursor.value.classList.contains('is-bottom')
        ) {
          cursor.value.classList.remove('is-bottom')
        }
      })

      updateCursor()
    })

    return {
      cursor,
      arrow,
    }
  },
})
